import logo from "./nyOlgaLogo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        <div
          style={{ width: "78vmin", textAlign: "left", paddingTop: "4vmin" }}
        >
          <p style={{ fontSize: "2.5vmin" }}>
            Vi utviklar teknologi som bidreg til meir verdiskaping i distriktet!
          </p>
          <p style={{ alignContent: "left", fontSize: "2vmin" }}>
            Olga Softcontrol AS er eit nyetablert selskap i Ørsta som utviklar
            programvare som gjer det enklare for små og mellomstore bedrifter å
            styre verksemda si. Dette inneberer produksjon, administrasjon og
            berekraft. Olga Softcontrol AS er resultatet av ti år med målretta
            arbeid for å forenkle og effektivisere flyt og verksemdsstyring i
            systerselskapet Rufo AS.
          </p>
          <p style={{ textAlign: "center", fontSize: "2vmin" }}>
            Ønskar du å kome i kontakt med oss?
          </p>
          <p style={{ textAlign: "center", fontSize: "2vmin" }}>
            Epost: ole@godflyt.no
          </p>

          <p style={{ textAlign: "center", fontSize: "2vmin" }}>
            Telefon: 478 18 440
          </p>
        </div>
      </header>
    </div>
  );
}

export default App;
